
const customEnglishMessages = {
  app: {
    toolbar:{
      text2:"¶",
      text1:"aragraph",
      features: "Features",
      usecases: "Usecases",
      blog: "Introducing Paragraph ↳",
      blogShort: "Blog ↳",
      demo: "Demo ↳",
      exitDemo: "← Exit Demo",
      pricing: "Pricing",
      contact: "Contact Us ",
    }  ,
    pages:{
      intro:{
        header: "Unleash the Future: Transforming Document Accessibility",
        header2: "Break Free: Edit Word Docs Without Leaving Your Platform",
        header3: "A new era of productivity",
        subHeader: "Word document integration for every platform",
        subHeader2: "Why settle with less."
      }
    }
  }
};

export default customEnglishMessages;
