import { FontWeights } from '@fluentui/react';



export const env = {
  colors: {
    // appBackground: themeSettings?.palette?.neutralQuaternaryAlt,
    //Fuel colors
    fuelDarkGray0: "#333333",
    fuelDarkGray0RGB: "51,51,51",
    fuelDarkGray1: "#808080",
    // fuelDarkGray0RGB: "51,51,51",
    fuelLightGray3: "#fcfcfc",
    fuelLightGray4: "#f9f9fb",
    
    fontColorPrimary: "white",

    //elementary
    menuGray1: "#e2e2e2",
    fontGray1: "#333333",
    midwayGray1: "#8B8B8B",

    //gradient
    // gradientStart: "rgba(41,165,135,1)",
    gradientStart: "#000807",
    // gradientEnd: "rgba(23,62,255,1)"
    gradientEnd: "#969696"

  },
  margins:{
    marginBottomSequential: "14px",
  },
  fonts:{
    logoText:"3vh",//"80px",
    minimum: "8px",
    footer: "10px",
    normal: 12,
    title: "14px",
    titleRegular: "1.5vh",
    titleMedium: "2vh",
    titleBig: "5vh",//"20px",
    // titleMega: "24px",
    //for sistematizar

    
  },
  sizes: {
    mobileWindowSize: 650,
    rainbowBarHeight: "30px",
    outerBarHeight: "10vh",//"100px"
  },
  borders: {
    placeholderSelected: 'solid 1px navy',
    placeholderUnselected: 'solid 1px transparent',
    radius: 4,//50//pill
    rainbowBarRadius:"3000px",
    selectedAction: 'solid 2px #888888',
    sheetRadius: "10px",
  },
  images:{
    logoBlack: 'logo-keyterms-simple-02.png',
    logoWhite: 'logo-key_generic_shadow.png',
    loginBackGround: 'background.jpeg',
    
  },
  language:{
    default: "en"
  },
  shadows:{
    menu:"0 2px 5px rgba(0, 0, 0, 0.2)"
  },
  transitions:{
    showDemo: "opacity 2s ease-out",
    startDemoTime: 2000,
    stopDemoTime: 0
  }
};



export const themeSettings = {
  
  typography: {
    fontFamily: 'Inter',
    fontWeight: FontWeights.regular,
    fontSize: env.fonts.normal,

    h1: {
      // fontFamily:  "Bebas Neue Book",
      // fontWeight: FontWeights.regular,
      fontSize: env.fonts.titleBig,
      textTransform: "none" as const,
      textShadow: env.shadows.menu,
      // textAlign: "center" as const,
    },
    h2: {
      // fontFamily:  "Bebas Neue Book",
      // fontWeight: FontWeights.regular,
      fontSize: env.fonts.titleMedium,
      textTransform: "none" as const,
      // textAlign: "center" as const,
    },
    h3: {
      // fontFamily:  "Bebas Neue Book",
      // fontWeight: FontWeights.regular,
      fontSize: env.fonts.titleRegular,
      textTransform: "none" as const,
      // textAlign: "center" as const,
    },
  },

};