import { Container, Grid, Paper, Typography, makeStyles, styled,TypographyVariant} from "@material-ui/core"
import { FC, useRef } from "react"
import Logo from "src/components/sharedComponents/Logo";
import { env } from "src/configuration/env";
import { useTranslate } from "src/i18n/i18n";

const Item = styled(Paper)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const useStyles = makeStyles(() => ({
    rootContainer:{
        minHeight: "100vh",
        width: "100%",
        // maxWidth:"100%",
        // backgroundColor: env.colors.midwayGray1
        backgroundImage: `linear-gradient(5deg, ${env.colors.gradientEnd} 13%, ${env.colors.gradientStart} 71%)`,

    },

    container:    {
        // backgroundColor: "lightgray",
        // height: "20px",
        // backgroundColor: env.colors.gradientStart,
        // minHeight: "100vh",
        width: "80vw",
        zIndex: 10,
        position: "relative",
        borderRadius: env.borders.sheetRadius,
        marginTop: "10vh",
        padding: "24px",
        maxWidth:"100%",
    },
    containerItem:{
        borderRadius: env.borders.sheetRadius,
        // backgroundColor: env.colors.gradientStart,
        backgroundColor: "transparent",
        border: `1px solid ${env.colors.fontColorPrimary}`,
        color: env.colors.fontColorPrimary,
        display:"grid",
    }
}));

const Features: FC = () => {
    const classes=useStyles()
    const translate = useTranslate();
    const featuresRef=useRef<HTMLDivElement>(null)


    return (
    <>
        <div className={classes.rootContainer}>
            <Container className={classes.container} id={"features"} ref={featuresRef}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Item className={classes.containerItem}>
                           
                        </Item>
                    </Grid>
                    <Grid item xs={6}>
                        <Item className={classes.containerItem}>

                            <Logo/>
                        </Item>
                    </Grid>
                    <Grid item xs={3}>
                        <Item>xs=4</Item>
                    </Grid>
                    <Grid item xs={2}>
                        <Item>xs=4</Item>
                    </Grid>
                    <Grid item xs={8}>
                        <Item>xs=8</Item>
                    </Grid>
                </Grid>
            </Container>
        </div>
    </>)
}

export default Features