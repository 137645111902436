import { makeStyles } from "@material-ui/core";
import { FC } from "react"
import { env } from "src/configuration/env";
import { useTranslate } from "src/i18n";


export const useStyles = makeStyles(() => ({
    container:{
        display:"flex",
        alignItems:"center",
    },
    logoText1:{
        display: "inline-block",
        fontFamily: "Lora",
        fontSize: env.fonts.titleMedium,
    },
    logoText2:{
        display: "inline-block",
        fontFamily: "Lora",
        fontSize: env.fonts.titleBig,
    },

  
  }), { name: 'Logo' });


const Logo: FC = () => {
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <div className={classes.container}>
            <span spellCheck={false} className={classes.logoText2} >{translate('app.toolbar.text2')}</span>
            <span spellCheck={false} className={classes.logoText1} >{translate('app.toolbar.text1')}</span>
        </div>
    )
}

export default Logo