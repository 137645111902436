import { withStyles, ButtonProps, Button } from "@material-ui/core";
import { env } from "../../configuration/env";


export const NavBarButton = withStyles({
    root: {
      fontSize: env.fonts.title,//env.fonts.normal,//12,
      fontWeight: 'normal',
      // border: `1px solid ${env.colors.fuelDarkGray0}`,
      color: env.colors.fuelDarkGray0,
      backgroundColor: env.colors.fontColorPrimary,
    //   fill: "white",
      borderRadius: env.borders.rainbowBarRadius,
      // minWidth: 70,
      textTransform: 'unset',
  
      '&:hover': {
        // backgroundColor: 'rgba(255, 255, 255, 0.15)',
        color: env.colors.fontColorPrimary,
        border: `1px solid ${env.colors.fontColorPrimary}`,//,border: `1px solid white`,

        backgroundColor: `rgba(${env.colors.fuelDarkGray0RGB}, 0.55)`,
        // border: `1px solid rgba(${env.colors.fuelDarkGray0RGB}, 0.55)`,
        // color: "white"
      },
      height: env.sizes.rainbowBarHeight,
      minHeight: env.sizes.rainbowBarHeight,
      paddingLeft: "16px",
      paddingRight: "16px",
      boxShadow: env.shadows.menu,
    },
  })((props: ButtonProps) => (
    <Button disableRipple {...props}/>
  ));



const buttonSecondary={
    root: {
        color: env.colors.fontColorPrimary,
        backgroundColor: "transparent",
        border: `1px solid ${env.colors.fontColorPrimary}`,//,border: `1px solid white`,
        '&:hover': {
          // backgroundColor: 'rgba(255, 255, 255, 0.15)',
          // color: env.colors.fuelDarkGray0,
          
          backgroundColor: `rgba(${env.colors.fuelDarkGray0RGB}, 0.55)`,
        },
    }
}

const buttonToolbar={
    root: {
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        '&:hover': {
            // backgroundColor: 'rgba(255, 255, 255, 0.15)',
            // color: env.colors.fuelDarkGray0,
            border: "none",
            backgroundColor: `rgba(${env.colors.fuelDarkGray0RGB}, 0.55)`,
          },
        boxShadow: "none"
    }
}

export const ButtonPrimary = ((props: ButtonProps) => (<NavBarButton {...props} />));

export const ButtonSecondary = withStyles(buttonSecondary)((props: ButtonProps) => (<NavBarButton  {...props}/>));

export const ButtonToolbar = withStyles(buttonToolbar)((props: ButtonProps) => (<NavBarButton  {...props}/>));
