import { Typography, makeStyles } from "@material-ui/core"
import { Link } from "react-router-dom"
import { ButtonPrimary } from "src/components/sharedComponents/button"
import { env } from "src/configuration/env"
import { useTranslate } from "src/i18n"
import { useAppState } from "src/provider/AppProvider"
import Features from "./Features"
import { useEffect, useRef, useState } from "react"
export const useStyles = makeStyles(() => ({

    containerIntro:    {
        // backgroundColor: "lightgray",
        // width: "50vw",
        // height: "20px",
        // height: "80vh",
        margin: "auto",
        width: "100%",
        maxWidth:"100%",
        color: env.colors.fontColorPrimary,
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        
        
        height: "90vh",
        position: "relative",
        zIndex:10,
        // overflowY:"hidden",
        padding:0,
        border:0
    },

    iframeDiv:{
        // width:"100vw",
        // height:"100vh",
        position:"fixed",
        // position:"relative",
        zIndex: 0,
        top:0,
        overflow:"hidden",
        width:"100%",
    },
    iframe:{
        width:"100%",
        // maxWidth:"100%",
        height:"100vh",
        overflow: "hidden",

        // position:"fixed",
        // position:"relative",
        // zIndex: 0,
        // top:0,
        // overflow:"hidden",
        // pointerEvents: "none" /* Allow click-through to content beneath */
        // filter: "grayscale(70%) brightness(0.5)"
        transition: env.transitions.showDemo,
    },
    intro:{
        width: "100%",
        maxWidth:"100%",
        height: "100%",
        position:"absolute",
        // filter: "grayscale(60%) b(0.2) ;",
        // backgroundColor: "rgba(0, 0, 0, 0.5)",
        top:0,
        left:0,
        transition: env.transitions.showDemo,

        
    },
    buttonDiv:{
        justifyContent: "center",
        display: "grid",
        alignItems: "center",
        height: "100%"
        // marginTop: "4em"
    },
    
    headersDiv:{
        position: "relative",
        width: "50vw",
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        transition: env.transitions.showDemo,
    }, 
    headers:{
        
        marginTop: "0.5em"
    },
    headerUnderline:{
        marginTop: "0.5em",
        textDecoration: "underline"
    },
    demoButton:{
        height: "3vw",
        paddingLeft: "2em",
        paddingRight: "2em",
        marginTop: "5em",

    },
    containerFeatures:{
        zIndex:10,
        transition: env.transitions.showDemo,
        opacity:1,
        display:"grid",
        position:"relative"
    }
}))

export  const AltHomepage =()=>{
    /**
     * CHANGELOG:
     * + Created
     * + Mar 26 2024: LEAF-69 changed url to take parameters
     */
    const { state,  } = useAppState();
    const [isIframeLoaded, setIsIframeLoaded]=useState(false)
    const [iframeLoadError, setIframeLoadError]=useState(false)
    const classes=useStyles(state)
    const translate = useTranslate();
    const contentRef = useRef(null);
    const iframeRef = useRef(null);
    

    // const onTransitionEnd=()=>{
    //     // setTimeout(() =>{
    //     //     setHideIntro(true)
    //     //   }, env.transitions.showDemoTime);
    // }

    const onIframeLoad=(e:any)=>{
        // console.log("Iframe loaded",e.target,  e.target.contentWindow.location.href)
        setIsIframeLoaded(true)
    }

    const onIframeError=()=>{
        console.log("Iframe error")
        setIframeLoadError(true)
    }

    // const url=`${process.env.REACT_APP_IFRAME_APP_URL}${window.location.hash.replace("#/", "")}`
    const url=`${process.env.REACT_APP_IFRAME_APP_URL}${window.location.search}`
    return (
    <>
        <div className={classes.containerIntro} id="container">
            {state.isIframeLoadable&&
            <div 
            id="iframe"
            className={classes.iframeDiv} 
            style={{
                pointerEvents: state.isDemo?undefined: "none",
                opacity: isIframeLoaded && !iframeLoadError?1: 0, 
            }}
            >
                <iframe
                    ref={iframeRef}
                    className={classes.iframe}
                    style={{
                        pointerEvents: state.isDemo?undefined: "none",
                        opacity: isIframeLoaded && !iframeLoadError?1: 0, 
                    }}
                    scrolling={!state.isDemo?"no":"undefined"}
                    title="Embedded Editor"
                    
                    src={url}
                    // src={"http://localhost:3002"}
                    onError={()=>onIframeError()}
                    frameBorder="0"
                    // allowFullScreen
                    onLoad={(e)=>onIframeLoad(e)}
                />
            
            </div>
            }
            <div className="intro">
                <div className={classes.intro}
                    ref={contentRef}
                    style={{
                        // background: !state.isDemo?"rgba(0, 0, 0, 0.7)":"transparent",
                        background: "rgba(0, 0, 0, 0.7)",
                        display: !state.hideDecorations?undefined: "none", 
                        opacity: !state.isDemo?1: 0, 
                        // opacity:1
                        
                    }}
                    // onTransitionEnd={()=>{onTransitionEnd()}}
                    >
                </div>
                <div className={classes.headersDiv} style={{
                    display: !state.hideDecorations?undefined: "none", 
                    opacity: !state.isDemo?1: 0, 
                    // maxHeight: !state.isDemo? undefined:0 , 
                    
                }
                    }>
                    <Typography variant="h1" >
                        {translate('app.pages.intro.header3')}
                    </Typography>
                    <Typography variant="h2" className={classes.headers}>
                        {translate('app.pages.intro.subHeader')}
                    </Typography>
                    <Typography variant="h2" className={classes.headerUnderline}>
                        {translate('app.pages.intro.subHeader2')}
                    </Typography>
                    <div className={classes.buttonDiv}>
                        <Link to="/#features">
                            <ButtonPrimary className={classes.demoButton}>
                                <Typography variant="h3">
                                    Learn More
                                </Typography>
                            </ButtonPrimary>
                        </Link>
                    </div>
                </div>
            </div>
            
        </div>
        <div className={classes.containerFeatures} 
            style={{
                display: !state.hideDecorations?undefined: "none", 
                opacity: !state.isDemo?1: 0,    
                }}
            id={"features"}
            // onTransitionEnd={()=>{onTransitionEnd()}}
        >
                <Features/>

        </div>  

    </>)
}


export default AltHomepage