import { AppBar} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { env } from '../../configuration/env';
import { useEffect, useRef, useState } from 'react';
import { Link} from 'react-router-dom';
import { useTranslate } from '../../i18n/i18n';
import {  ButtonSecondary, ButtonToolbar } from '../sharedComponents/button';
import { handleWindowResize } from '../../utils/responsive';
import { Height } from '@material-ui/icons';
import { useAppState } from 'src/provider/AppProvider';



export const useStyles = makeStyles(() => ({
  /**
   * CHANGELOG:
   * + Nov 21: KEYT-490 New rainbow developments
   */
  outerbar:{
    minHeight: "0px", 
  },
  bar: {
    // border: 'none',
    // height: sizes.toolbarHeight,
    boxShadow: 'none',
    
    // color: 'white',
    fontSize: env.fonts.normal,//12,
    fontWeight: 'bold',
    //changes to new bar
    borderRadius: "0px",
    height: env.sizes.outerBarHeight,
    // height: "20vh",
    // height: "60px",//env.sizes.rainbowBarHeight,
    // color: 'transparent',
    // backgroundColor: env.colors.fuelDarkGray0,//"transparent",
    // backgroundColor: "transparent",//,
    backgroundColor: env.colors.gradientStart,
    borderTopColor: "transparent",//env.colors.fuelGray0,
    // borderBottomWidth: "30px",
    // borderBottomColor: "transparent",//env.colors.fuelGray0,
    borderLeftWidth: "0px",
    borderRightWidth: "0px",
    alignContent: "center",
    flexWrap: "wrap",
    // paddingLeft: "4px",
    // paddingTop: "10px",
    justifyContent: "center",
    display: 'inline-block',    
    position:"relative",
    // zIndex: 1000,
    zIndex: 11000,
    transition: env.transitions.showDemo

  },
  toolbar: {
    display: 'inline-block',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    
    paddingRight: 0,
    //changes to new bar
    
   
    minHeight: env.sizes.rainbowBarHeight,
    // borderBottomWidth: "20px",
    verticalAlign: "middle",
    // height: env.sizes.outerBarHeight,
    // minHeight: env.sizes.rainbowBarHeight,
    textAlign: "center",
    // line-height: 90px;
    lineHeight: env.sizes.outerBarHeight,
    height: "inherit",
    
    
  },
  toolbar0: {
    // paddingLeft: 11,
    minWidth: "unset",
    maxWidth: "unset",
    width: "25%",
    justifyContent: "start",  
    top: "50%",
    
  },
  toolbar1:{
    minWidth: "50%",
    // maxWidth "80%",
    maxWidth: "50%",
    height: env.sizes.rainbowBarHeight,
    lineHeight: "unset",
    
  },
  toolbar2: {
    minWidth: "unset",
    maxWidth: "unset",
    width: "25%",
    justifyContent: "end"
  },
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    display: 'inline-block',
  },
  logoText0:{
    fontSize: env.fonts.logoText,
    fontWeight: 400,
    color: "white",
    // paddingLeft: "2px",
    // display: "flex",
    // paddingTop: "2px",
    cursor: 'pointer',
    border: "none",
    textDecoration: "none",
    display:"block",
    textShadow: env.shadows.menu
  },
  logoText1:{
    fontFamily: "Lora",
    
  },
  logoText2:{
    fontFamily: "Lora",
    fontSize: env.fonts.titleBig,
    lineHeight: "normal"
  },
  rainbow:{
    // background: env.colors.fuelDarkGray1,//"rgb(0,131,224)",
    // background: `rgba(${env.colors.fuelDarkGray0RGB},0.4)`,
    // backgroundImage: env.colors.fuelDarkGray1,//"linear-gradient(135deg, rgba(0,131,224,1) 0%, rgba(21,49,147,1) 18%, rgba(157,24,213,1) 49%, rgba(255,96,24,1) 92%, rgba(255,0,84,1) 100%)",
    // width: "50%",
    // background: "rgb(11,52,255)",
    // backgroundImage: "linear-gradient(326deg, rgba(23,62,255,1) 13%, rgba(41,165,135,1) 71%);",
    borderRadius: env.borders.rainbowBarRadius,
    border: `1px solid ${env.colors.fontColorPrimary}`,
    display: "inline-block",
    textWrap: "nowrap",
    boxShadow: env.shadows.menu,
  },
  toolbarTablet0:{
    width:"50%"
  },
  stopDemoButton:{
    zIndex: 111110,
    opacity:1,
    // position: "absolute"
  }

}), { name: 'AppBar' });



export default function Navbar() {
  
  const classes = useStyles();
  const [responsiveType, setResponsiveType]=useState(handleWindowResize(window))
  const translate = useTranslate();
  const { state, setState } = useAppState();
  const [hideAppBar, setHideAppBar]=useState(false)
  const contentRef = useRef(null);
  const demoButtonRef = useRef(null);
  const stopDemoButtonRef = useRef(null);

  const onTransitionEnd=(event:any)=>{
    if(event.target===contentRef.current) {
      setTimeout(() =>{
        setHideAppBar(true)
      }, 1000);
    }
}
  const handleResize = () => {
    setResponsiveType(handleWindowResize(window))
  }

  const applyDemoButtonPositionToStopDemoButton=()=>{
    if(!demoButtonRef?.current) return
    const divRect = (demoButtonRef?.current as any).getBoundingClientRect();

    const targetElement:any = stopDemoButtonRef.current;
    if(!targetElement) return
    targetElement.style.position = 'absolute';
    targetElement.style.top = `${divRect.top}px`;
    targetElement.style.left = `${divRect.left}px`;
    targetElement.style.width = `${divRect.width}px`;
    targetElement.style.height = `${divRect.height}px`;

  }

  const startDemo=()=>{
    setState({...state, isDemo: true})
    setTimeout(() =>{
      setState({...state,isDemo: true, hideDecorations: true})
    }, env.transitions.startDemoTime);
  }
  const stopDemo=()=>{
    // setState({...state, isDemo: false, hideDecorations:false})

    setState({...state, hideDecorations: false})
    setTimeout(() =>{
      setState({...state,isDemo: false, hideDecorations: false})
    }, env.transitions.startDemoTime);
    
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize, false);

  }, []);

  useEffect(()=>{
    applyDemoButtonPositionToStopDemoButton()

  }, [demoButtonRef?.current])

  return (
    <>
    <AppBar className={classes.bar} square={false} color={'inherit'} 
    ref={contentRef}
    style={{
      // display: state.isDemo? "none": undefined
      display: !state.hideDecorations?'inline-block': "none", 
      // display: 'inline-block',
      opacity: !state.isDemo?1: 0, 
    }}
    onTransitionEnd={(event)=>{onTransitionEnd(event)}}
    >
      {responsiveType[0] && //mobile
      <>
        <div className={classes.toolbar + " "+classes.toolbar0+ " "+classes.toolbarTablet0}>
          <Link to="/" className={classes.logoText0}>
            <span spellCheck={false} className={classes.logoText2} >{translate('app.toolbar.text2')}</span>
            <span spellCheck={false} className={classes.logoText1} >{translate('app.toolbar.text1')}</span>
          </Link>
        </div>
        <div className={classes.toolbar + " "+classes.toolbar0+ " "+classes.toolbarTablet0}>
          <Link to="/contact">
            <ButtonSecondary >
              {translate('app.toolbar.blogShort')}
            </ButtonSecondary>
          </Link>
        </div>   
      </>
      }
      { !responsiveType[0] && responsiveType[1] && //Tablet
      <>
        <div className={classes.toolbar + " "+classes.toolbar0+ " "+classes.toolbarTablet0}>
          <Link to="/" className={classes.logoText0}>
            <span spellCheck={false} className={classes.logoText2} >{translate('app.toolbar.text2')}</span>
            <span spellCheck={false} className={classes.logoText1} >{translate('app.toolbar.text1')}</span>
          </Link>
        </div>
        <div className={classes.toolbar + " "+classes.toolbar0+ " "+classes.toolbarTablet0}>
          <Link to="/contact">
            <ButtonSecondary>
            {translate('app.toolbar.blog')}
            </ButtonSecondary>
          </Link>
        </div>   
      </>

      }
      {responsiveType[2] &&//Desktop
      <>
        <div className={classes.toolbar + " "+classes.toolbar0}>
          <Link to="/" className={classes.logoText0}>
            <span spellCheck={false} className={classes.logoText2} >{translate('app.toolbar.text2')}</span>
            <span spellCheck={false} className={classes.logoText1} >{translate('app.toolbar.text1')}</span>
          </Link>
        </div>
        
        <div className={classes.toolbar + " "+classes.toolbar1}>
          <div 
          className={classes.rainbow}
          >
            <Link to="/#features">
              <ButtonToolbar >
                {translate('app.toolbar.features')}
                </ButtonToolbar>
            </Link>
            <Link to="/#usecases" >
              <ButtonToolbar >
                  {translate('app.toolbar.usecases')}
                </ButtonToolbar>
              
            </Link>
            <Link to="/#usecases" >
              <ButtonToolbar >
                  {translate('app.toolbar.pricing')}
                </ButtonToolbar>
            </Link>

          </div>
          
        </div>      
        <div className={classes.toolbar + " "+ classes.toolbar2}
          ref ={demoButtonRef}>
          {/* <Link to="/contact"> */}
            {state.isIframeLoadable &&
            <ButtonSecondary 
              
              onClick={()=>startDemo()}>
             {translate('app.toolbar.demo')}
            </ButtonSecondary>}
          {/* </Link> */}
        </div>   
      </>
      }
      
    </AppBar>
      <div 
      className={classes.toolbar + " "+ classes.toolbar2+" "+classes.stopDemoButton}
      style={{
        opacity: !state.hideDecorations?0: 1, 
        zIndex: !state.hideDecorations?0: 11110, 
      }}
      ref={stopDemoButtonRef}>
        <ButtonSecondary 
          
          // className={}
          onClick={()=>stopDemo()}
          style={{
            // opacity: hideAppBar?1: 0, 
            // zIndex: hideAppBar?0: 11110, 
          }}
        >
        {translate('app.toolbar.exitDemo')}
      </ButtonSecondary>
    </div>
   </>

  );
}
