import * as React from 'react';
import Polyglot from 'node-polyglot';

import englishMessages from './en';
import  customPortugueseMessages from './pt';
import { env } from '../configuration/env';

const frontendPrefix = 'CB';

export const useTranslateCB = () => {
  const translate = useTranslate();

  return React.useCallback((toTranslate:string, options?:any) => translate (`${frontendPrefix}.${toTranslate}`, options), [ translate ]);
}

type StringMap = {
  [key: string]: StringMap | string | undefined;
}

const getMessages = (locale:string):StringMap|Promise<StringMap> => {
  if (locale === 'en') return englishMessages;
  if (locale === 'pt') return customPortugueseMessages;
  return import(`./${locale}`).then((messages) => messages.default);
};

const createI18nProvider = (initialLocale: string = env.language.default, polyglotOptions: any = {}) => {
  let locale = initialLocale;
  const messages = getMessages(initialLocale);
  if (messages instanceof Promise) {
      throw new Error(
          `The i18nProvider returned a Promise for the messages of the default locale (${initialLocale}). Please update your i18nProvider to return the messages of the default locale in a synchronous way.`
      );
  }
  const polyglot = new Polyglot({
      locale,
      phrases: { '': '', ...messages },
      ...polyglotOptions,
  });
  let translate = polyglot.t.bind(polyglot);

  return {
      translate: (key: string, options: any = {}) => translate(key, options),
      changeLocale: (newLocale: string) =>
          // We systematically return a Promise for the messages because
          // getMessages may return a Promise
          Promise.resolve(getMessages(newLocale as string)).then(
              (messages: StringMap) => {
                  locale = newLocale;
                  const newPolyglot = new Polyglot({
                      locale: newLocale,
                      phrases: { '': '', ...messages },
                      ...polyglotOptions,
                  });
                  translate = newPolyglot.t.bind(newPolyglot);
              }
          ),
      getLocale: () => locale,
  };
}

let _i18nProvider = undefined as undefined|ReturnType<typeof createI18nProvider>;

const getI18nProvider = () => {
  if (!_i18nProvider) _i18nProvider = createI18nProvider();

  return _i18nProvider;
} 

export const useTranslate = () => {
  const i18nProvider = getI18nProvider();
  const locale = env.language.default;
  
  return React.useCallback(
    (key: string, options?: any) => i18nProvider.translate(key, options) as string, 

    [ i18nProvider, locale ]
  );
}

export const useLocale = () => {
  const locale = env.language.default;
  return locale;
};

// export const useSetLocale = () => {
//   const i18nProvider = getI18nProvider();
//   const { setLocale } = useAppContext();

//   return React.useCallback(
//       (newLocale: string) =>
//           new Promise(resolve => {
//               resolve(i18nProvider.changeLocale(newLocale));
//           })
//           .then(() => {
//               setLocale(newLocale);
//           })
//           .catch(error => {
//               console.error(error);
//           }),
//       [i18nProvider, setLocale]
//   );
// };

