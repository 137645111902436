import { createContext, useContext, useEffect, useState } from 'react';

// Define the type for your state object
interface State {
  counter: number;
  userName: string;
  isDemo: boolean;
  isIframeLoadable: boolean;
  hideDecorations:boolean;
}

// Define the initial state
const initialState: State = {
  counter: 0,
  userName: '',
  isDemo: false,
  isIframeLoadable:false,
  hideDecorations:false
};

// Define the context type
interface ContextType {
  state: State;
  setState: React.Dispatch<React.SetStateAction<State>>;
}

// Create the context
const AppContext = createContext<ContextType | undefined>(undefined);

// Create the provider component
export const AppProvider: React.FC = ({ children }) => {
  /**
   * CHANGELOG:
   * + Created
   * + Mar 26 2024: LEAF-69 changed url to take parameters
   */
  const [state, setState] = useState<State>(initialState);


  useEffect(()=>{
    // const url:string|undefined=process.env.REACT_APP_IFRAME_APP_URL
    // const url=`${process.env.REACT_APP_IFRAME_APP_URL}${window.location.hash.replace("#/", "")}`
    const url=`${process.env.REACT_APP_IFRAME_APP_URL}${window.location.search}`
    if(!url) return
    fetch(url, { method: 'HEAD' }).then((response:any)=>{
      setState({...state, isIframeLoadable:true})
    }).catch((error:any)=>{
        //do nothing
    })
}, [])

  return (
    <AppContext.Provider value={{ state, setState }}>
      {children}
    </AppContext.Provider>
  );
};

// Create a custom hook to use the context
export const useAppState = () => {
  const context = useContext(AppContext);
  
  if (!context) {
    throw new Error('useAppState must be used within an AppProvider');
  }
  return context;
};