import { Container, Typography, makeStyles} from "@material-ui/core";
import { useRef } from "react";
import Intro from "./Intro";
import { env } from "../configuration/env";
import Features from "./Features";


const useStyles = makeStyles(() => ({

    container:    {
        // backgroundColor: "lightgray",
        // height: "20px",
        minHeight: "100vh"
    },
    containerAlternate:{
        backgroundColor: env.colors.fuelDarkGray1
    }
}));


export  const Homepage =()=>{
    const classes=useStyles()

    const featuresRef=useRef<HTMLDivElement>(null)
    return (
    <>
        {/* <Container className={classes.container}> */}
            <Intro/>

        {/* </Container> */}
        {/* <Container className={classes.container + ' ' + classes.containerAlternate} id={"features"} ref={featuresRef}
        > */}
            <Features/>

        {/* </Container> */}
        <Container className={classes.container} id={"usecases"}>
            Usecases
        </Container>
    </>)
}