

export const handleWindowResize=(window:Window)=> {  
    const resolution = window.innerWidth;  
    const isMobile = resolution <= 650;  
    const isTablet = resolution<= 1024;  
    const isDesktop = !isMobile && !isTablet;  // update state

    // const smallMobile= isMobile
    // const genericDesktop=isTablet||isDesktop
    return [isMobile, isTablet, isDesktop]
    
}