

import { Typography, makeStyles } from "@material-ui/core";
import { useTranslate } from "../i18n/i18n";
import { env } from "../configuration/env";
import { ButtonPrimary, ButtonSecondary } from "src/components/sharedComponents/button";
import { FC } from "react";
import { Link } from "react-router-dom";

// import FileEditor from "leaf-editor";

const useStyles = makeStyles(() => ({

    container:    {
        // backgroundColor: "lightgray",
        // width: "50vw",
        // height: "20px",
        // height: "80vh",
        margin: "auto",
        width: "100%",
        color: env.colors.fontColorPrimary,
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        
        
        height: "80vh",
        position: "relative",
    },

    headerImage:{
        background: 'url("content/Screenshot.jpeg")',
        backgroundSize: "cover", /* Optional: Adjusts the size of the background image */
        backgroundPosition: "center", /* Center the background image */
        filter: "grayscale(70%) brightness(0.15);", /* Convert the image to grayscale */
        width: "100%",
        height: "100%",
        position:"absolute",
        top: 0,
        left: 0,
        // backgroundSize: "100%",
        // backgroundRepeat: "no-repeat",
        // minHeight: "80vh",  
    },

    buttonDiv:{
        justifyContent: "center",
        display: "grid",
        alignItems: "center",
        height: "100%"
        // marginTop: "4em"
    },
    
    headersDiv:{
        position: "relative",
        width: "50vw",
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
    }, 
    headers:{
        
        marginTop: "0.5em"
    },

    demoButton:{
        height: "3vw",
        paddingLeft: "2em",
        paddingRight: "2em",
        marginTop: "5em",

    },



}));

 
const Intro: FC = () => {
    const classes=useStyles()
    const translate = useTranslate();
    return (
    <div className={classes.container}>
        
        
        <img className={classes.headerImage} alt="" />
            {/* <div className={classes.headerImage}> */}
        <div className={classes.headersDiv}>
            <Typography variant="h1" >
                {translate('app.pages.intro.header2')}
            </Typography>
            <Typography variant="h2" className={classes.headers}>
                {translate('app.pages.intro.subHeader')}
            </Typography>
            <div className={classes.buttonDiv}>
                <Link to="/#features">
                    <ButtonPrimary className={classes.demoButton}>
                        <Typography variant="h3">
                            Learn More
                        </Typography>
                    </ButtonPrimary>
                </Link>
            </div>
        </div>
        
            {/* </div> */}
            
        
        
        
    </div>
    )
}

export default Intro