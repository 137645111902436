import { Route, Router, useLocation } from 'react-router';
import { createHashHistory } from 'history';
import React, { useEffect, } from "react";
import { ContactPage } from "./pages/ContactPage";
import { Homepage } from "./pages/Homepage";
import { ThemeProvider, createTheme, makeStyles } from "@material-ui/core/styles";
import { env, themeSettings } from "./configuration/env";
import { CssBaseline } from "@material-ui/core";
import AltHomepage from './pages/AltHomepage';
import Navbar from './components/navbar/navbar';
import { AppProvider } from './provider/AppProvider';


export const useStyles = makeStyles(() => ({
  app:{
    // backgroundImage: "linear-gradient(5deg, rgba(23,62,255,1) 13%, rgba(41,165,135,1) 71%)",
    // backgroundImage: "linear-gradient(5deg, rgba(23,62,255,1) 13%, rgba(41,165,135,1) 71%)",
    backgroundImage: `linear-gradient(5deg, ${env.colors.gradientEnd} 13%, ${env.colors.gradientStart} 71%)`,

  },
  main:{
    // paddingTop: "1vw"
  }
}));

let theme = createTheme(themeSettings);
// theme = responsiveFontSizes(theme);


const ThemedApp=()=>{
  const classes=useStyles()

  const location = useLocation();

  useEffect(() => {
      // execute on location change
      if(!location.hash){
        window.scrollTo({
          top:0,
          behavior:"smooth"
        })
      }else{
        const violation = document.getElementById(location.hash.replace("#", "")); 
        if(!violation) return
        window.scrollTo({
          top:violation.offsetTop-45,
          behavior:"smooth"
        })
      }
      
  },[location]);

  return(
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <Header /> */}
      <Navbar />
      <main className={classes.main}>
        <div>

          <Route path="/" exact component={AltHomepage} />
          
          <Route path="/contact" component={ContactPage} />
        </div>
      </main>
      <Route path="/old" exact component={Homepage} />
    </ThemeProvider>
  )
}

const App = () => {
  const classes=useStyles()
  const [ cm ] = React.useState(() => {
    return {
      history: createHashHistory(),
    }
  });

  return (
      <div className={classes.app}>
        {/* <GlobalStyle  /> */}
        <AppProvider>
          <Router history={cm.history}>
            <ThemedApp/>
          </Router>
        </AppProvider>
      </div>
    
  );
}


export default App

